import React, { useState, useEffect } from 'react';
import { Modal, CardColumns, Button } from "react-bootstrap";
import DatasheetModal from './datasheetModal';
import Homefilters from './../api/homeproducts';
//import Homefilters from '../api/homeproducts.json';
import Productleftdatasheet from './productleftdatasheet';

// var Homefilters = [
// {
//     id: 0,
//     pname: "EN2010",
//     name: "Single phase EMI Filter General Purpose  1-30 Amp",
//     img: "en2010.png",
//     modallist: [{
//         id: 1,
//         datasheetNum: "EN2010-1-F, 1Amp",
//         sheetOpen: "EN2010-1-F.pdf"
//     }, {
//         id: 2,
//         datasheetNum: "EN2010-2-F, 2Amp",
//         sheetOpen: "EN2010-2-F.pdf"
//     }, {
//         id: 3,
//         datasheetNum: "EN2010-3-F, 3Amp",
//         sheetOpen: "EN2010-3-F.pdf"
//     }],
// }
// ]

const Homeproductleft = (props) => {

  // let [show1, setShow1] = useState("none");
  // let modal = () => {
  //   setShow1("show");
  // };
  // let handleClose = () => {
  //   setShow1("none");
  // }


  // const [show, setShow] = useState(false);
  // //const [state, setstate] = useState({modalone:"modal0",modaltwo:"modal1"});
  // const [state, setstate] = useState({modalName:Homefilters[0].id});
  // console.log(state.modalName);
  // let handleShow = () => {
  //   setShow(true);
    
  // }
  // let handleClose = () => setShow(false);


  //console.log(JSON.stringify(Homefilters, null, 5));
  //console.log(Homefilters[0].modallist.datasheetNum);
  //console.log(JSON.stringify(Homefilters[0].modallist, null, 5));
  //console.log(JSON.stringify(Homefilters[0].modallist[0].datasheetNum));
  // console.log(Homefilters[0].modallist); 
  const filters = Homefilters.map((cvalue) => { 
      return(
          <>
          <li id={cvalue.id}>
              <div className="popupaoc-image-popup">
                  <a className="paoc-popup popupaoc-link-image" href="javascript:void(0);" variant="primary" data-toggle="modal" data-target={`#modal${cvalue.id}`}>
                      <img className="popupaoc-img" src={'images/'+cvalue.img} alt={'EMC/EMV '+cvalue.pname} />
                      </a>
                  <h4 className="popupaoc-image-title"><span>{cvalue.pname}</span>{cvalue.name}</h4>
              </div>
          </li>
          {/* <li id={cvalue.id}>
              <div className="popupaoc-image-popup">
                  <a className="paoc-popup popupaoc-link-image" href="javascript:void(0);" variant="primary" onClick={handleShow}>
                      <img className="popupaoc-img" src={'images/'+cvalue.img} alt={'EMC/EMV '+cvalue.pname} />
                      </a>
                  <h4 className="popupaoc-image-title"><span>{cvalue.pname}</span>{cvalue.name}</h4>
              </div>
          </li> */}
          {/* <Modal id={state.modalName} show={show} onHide={handleClose} className={`paoc-popup-modal-cnt`}>
            <Modal.Header closeButton>
                <Modal.Title><h2 id="mce_0">{cvalue.pname} Commercial/Industrial Single Phase EMI Filters </h2></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className="display-posts-listing">
                    
                    {/* <Productleftdatasheet sheetUrl="EN2010-1-F" sheetName={cvalue.modal.datasheetNum} /> */}
      {/* <li className="listing-item"><a className="title" target="_blank" href="#">{cvalue[0].modallist.datasheetNum}</a></li> 
    
                </ul>
            </Modal.Body>
        </Modal> */}
          
      </>
      )
  });
  return (
    <>
      {filters} 
      <DatasheetModal/>
      
      {/* <li id={props.id}>
        <div className="popupaoc-image-popup">
          <a className="paoc-popup popupaoc-link-image" href="javascript:void(0);" variant="primary" data-toggle="modal" data-target="#exampleModal">
            <img className="popupaoc-img" src={'images/' + props.img} alt={'EMC/EMV ' + props.pname} />
          </a>
          <h4 className="popupaoc-image-title"><span>{props.pname}</span>{props.name}</h4>

        </div>
      </li> */}
      
    </>
  )
}

export default Homeproductleft;
