import React from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";

const Header = () => {
    return (
        <>
            <div class="topHeader">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-6"><span class="txtWhite">For free samples | <strong>Call / WhatsApp: <a href="tel:8929118319">+91-8929118319</a></strong> | <strong>Email: <a href="mailto:ra@emcemv.com">Ra@emcemv.com</a></strong></span></div>
                        <div className="col-md-3 offset-3"><div id="google_translate_element"></div></div>
                    </div>
                </div>
            </div>
            <header id="header">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-3">
                            <a href="/" className="logo"><img src="images/logo.jpeg" alt="header logo" /></a>
                        </div>
                        {/*  */}
                        <div className="col-md-9 text-right">
                            <span className="nav_overlay"></span>
                            <nav className="main-nav">
                                <div className="toggle-menu-btn">
                                    <div className="hamburger hamburger--elastic js-hamburger">
                                        <div className="hamburger-box">
                                            <div className="hamburger-inner"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu-main-menu-container">
                                    <ul id="menu-main-menu" className="menu">
                                        {/* <li id="menu-item-1482" ></li> */}
                                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-46 current_page_item menu-item-1482"><Link to="/">Home</Link></li>
                                        <li id="menu-item-2679" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2679"><Link to="/">Products</Link></li>
                                        <li id="menu-item-1495" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1495"><Link to="/about">About Us</Link></li>
                                        <li id="menu-item-2685" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2685"><Link to="/dealers">Dealers & Distributors</Link></li>
                                        <li id="menu-item-1481" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1481"><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                    
                                </div>
                                <div class="sticker"><h3><a href="tel:8929118319"><img src="images/headfone.jpg" style={{width: "27px"}} alt="" /> Live <span>24/7</span> support available in all languages</a></h3></div>
                            </nav>
                        </div>
                    </div>
                </div>

            </header>
            {/* Second Menu */}
            <div className="second-menu">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="menu-second-menu-header-container">
                                <ul id="menu-second-menu-header" className="menu"><li id="menu-item-2670" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2670"><a href="emi-rfi-filters/index.html">EMI/RFI Filters</a></li>
                                <li id="menu-item-2669" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2669"><a href="javascript:;">Three Phase EMI Filters</a></li>
                                <li id="menu-item-2668" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2668"><a href="javascript:;">Single Phase EMI Filters</a></li>
                                <li id="menu-item-2671" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2671"><a href="javascript:;">Military/COTS EMI Filters</a></li>
                                <li id="menu-item-2680" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2680"><a href="javascript:;">DC EMI FILTERS</a></li>
                                <li id="menu-item-2869" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2869"><a href="javascript:;">TEMPEST FILTERS</a></li>
                                <li id="menu-item-2870" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2870"><a href="javascript:;">EMI/EMC CHOKE</a></li>
                                </ul>
                                <a href="https://api.whatsapp.com/send?phone=918929118319&text=Hello" class="WhatsIcon" target="_blank" title="Send Query">
                                        <i class="fab fa-whatsapp my-float animate-pulse"></i>
                                    </a>
                            </div>
                            <div className="search"><aside id="search-3" className="widget widget_search">

                                {/* <form role="search" method="get" className="search-form" action="https://www.premieremc.com/">
            <label for="search-form-1">
                <span className="screen-reader-text">Search for:</span>
            </label>
            <input type="search" id="search-form-1" className="search-field" placeholder="Search &hellip;" value="" name="s" />
            <button type="submit" className="search-submit"><svg className="icon icon-search" aria-hidden="true" role="img"> <use href="#icon-search" xlink:href="#icon-search"></use> </svg><span className="screen-reader-text">Search</span></button>
        </form> */}
                            </aside></div>
                        </div>
                    </div>
                </div>
                <div className="sample-line"><div className="container"><marquee className="marq" direction="left" loop="">1 week lead time for samples/prototypes. 6 week Lead time for production units.  No tariff Surcharge. 20% minimum cost savings. Duty Free company. For Live support Click on Whatsapp Icon - 24x7 Support</marquee></div></div>
            </div>
        </>
    );
}

export default Header;
