import React, {useState} from "react";
import {BrowserRouter, Switch, Route, Link} from "react-router-dom";
import './bootstrap.min.css';
//import 'bootstrap/dist/js/bootstrap.min.js';
import 'font-awesome/css/font-awesome.css';
import './App.css';
import Header from './components/include/header';
import Footer from './components/include/footer';
import Homescreen from './components/homeScreen';
import About from './components/About';
import Dealers from './components/Dealers';

function App() {
  return (
    <div id="page" className="site">
      <BrowserRouter>
      <Switch>
          <Route exact path="/">
            <Header/>
            <Homescreen/>
            <Footer/>
          </Route>
          <Route path="/about">
            <Header/>
            <About/>
            <Footer/>
          </Route>
          <Route path="/contact">
            <Header/>
            <Footer/>
          </Route>
          <Route path="/dealers">
            <Header/>
            <Dealers/>
            <Footer/>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
