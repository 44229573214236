import React from 'react';

const Dealers = () => {
	return (
		<>
			<section className="emi-sec p-txt-18">
				<div className="container">
					<div className="sec-w-900">
						<div className="row">
							<div className="col-md-12">
								<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBnHqDK4z7vtgBOZP8DIEk6UhAqIEAW3Mo"></script>





								<div className="content-container-lg">
									<div className="container">
										<div className="row">
											<div className="col-md-4">










											</div>
											<div className="col-md-8">

												<div className="google-map rep-map" data-zoom="16">









													<div className="marker" data-lat="61.107704" data-lng="-150.0007">
														<div className="content-tight">
															<h6 className="scrunch">Alaska</h6>
															<h5 className="scrunch-top"></h5>
															<p><strong>Regional Manager</strong> <br />Alonso Bustamante <br /><strong>Email:</strong> <a href="mailto:Alonso.Bustamante@astrodynetdi.com" rel="noopener">Alonso.Bustamante@astrodynetdi.com</a> <br /><strong>Phone:</strong> 908-850-5088 x1866</p>
														</div>
													</div>






















												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Dealers;
