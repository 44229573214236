import React from 'react'

const About = () => {
    return (
        <>
        <section className="emi-sec p-txt-18">
			<div className="container">
				<div className="sec-w-900">
					<div className="row">
						<div className="col-md-12">							
<p>BLA ETECH PVT LTD is an Indian EMI / EMC filter manufacturing company providing world-class products of EMI EMC FILTERS (complete range) from 1 Amp to 2500 Amps. Our complete EMI/EMC FILTER product offerings, proven skills and expertise, and continued investment in new facilities and technologies ensure a winning experience for our customers and give them the competitive advantage in the marketplace. From quick turn to high volume, we can fulfill all demands within time and budget.</p>
<p><br/>BLA ETECH Pvt. Ltd is an ISO 9001:2008 certified, financially sound & stable, manufacturing company, well-recognized for quick as well as high volume EMI EMC FILTER manufacturing. Over time, we have built our reputation on a strong foundation of total customer satisfaction, rapid response and streamlined efficiency .Rooted in New Delhi, the capital of India, we have now branched out to serve customers all over the world throughout the US, Europe, Asia, Australia and anticipate this growth to continue, nurtured by complete customer satisfaction, world-class technology, seamless logistics, highly Skilled personnel and engineering staff.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
        </>
    )
}

export default About;
