import React from 'react'

const Footer = () => {
    return (
        <footer className="footer-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-3 col-big-2">
                            <div className="product-box-f">
                                <h3>Products Range</h3>
                                <div className="custom-menu-class"><ul id="menu-footer-menu" className="menu"><li id="menu-item-1245" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1245"><a href="#">EMI/RFI FILTERS</a></li>
                                    <li id="menu-item-1244" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1244"><a href="#">Three Phase Filters</a></li>
                                    <li id="menu-item-1243" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1243"><a href="#">Single Phase Filters</a></li>
                                    <li id="menu-item-1575" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1575"><a href="#">Military/COTS EMI Filters</a></li>
                                    <li id="menu-item-2871" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2871"><a href="#">TEMPEST FILTERS</a></li>
                                    <li id="menu-item-2872" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2872"><a href="#">SCIF FILTERS</a></li>
                                    <li id="menu-item-1241" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1241"><a href="#">DC FILTERS</a></li>
                                    <li id="menu-item-1474" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1474"><a href="#">Custom Filters</a></li>
                                </ul></div>

                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-big-8">
                            <div className="social-box-f">
                                <aside id="text-2" className="widget widget_text">
                                    <div className="textwidget">
                                        <p><a href="index.html">
                                            <img className="alignnone wp-image-1485" src="images/logo.jpeg" alt="footer logo" width="202" height="" />
                                        </a></p>
                                        <p><strong>EMC EMV Filters</strong> is a global leader in the design and manufacture of AC/DC, EMI Power Line Filters, for all Environments from Military to Industrial to Commercial. With over 50 standard product series and unlimited custom capability, EMC EMV Filters is uniquely positioned to meet the stringent cost and Schedule demands required by today’s market. With manufacturing locations in Asia and our prototype/quick-turn production and EMI and Safety labs in the India, EMC EMV Filters has the fastest delivery and design to market response in the industry.</p>
                                    </div>
                                </aside>
                                <ul>
                                    <aside id="custom_html-15" className="widget_text widget widget_custom_html"><div className="textwidget custom-html-widget"><li><a href="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>

                                        <li><a href="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a></li>

                                        <li><a href="#" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li></div></aside>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-3 col-big-2">
                            <aside id="custom_html-14" className="widget_text widget widget_custom_html"><div className="textwidget custom-html-widget">
                                <div className="contact-box-f">
                                    <h3>Contact Us</h3>
                                    <p>EMC EMV Filters,<br /> A-42, Wazirpur Industrial Area<br /> New Delhi - 110052, India</p>
                                    <p>
                                        <b>Phone :</b> +91-11-47483290<br />
                                        <b>Mobile :</b> +91-8860638008<br />
                                        <b>Email :</b> <a href="mailto:aj@emcemv.com">aj@emcemv.com</a>
                                    </p>
                                </div>
                            </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </footer>
    )
}

export default Footer;
