import React, { useState } from 'react';
import Homeproductleft from './homeproductleft';

const Homescreen = (props) => {
    return (
        <>
            <section className="Home-first-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-xl-7 col-lg-6 pt-3 left-column-border pr-5">
                            <div className="list-of-category">
                                <ul>
                                    <Homeproductleft/>
                                    
                                    {/* <Homeproductleft pname="EN2010" name="Single phase EMI Filter General Purpose  1-30 Amp" img="en2010.png" /> */}
                                    {/* <Homeproductleft pname="EN2020" name="Single phase EMI Filter General Purpose  1-30 Amp" img="en2020.png" />
                                    <Homeproductleft pname="EN2030" name="Single Phase EMI Filter with High Attenuation Performance 1-30 Amp" img="en2030.png" />
                                    <Homeproductleft pname="EN2060" name="Single Phase EMI Filter Dual Stage General Purpose 1-30 Amp" img="en2060.png" />
                                    <Homeproductleft pname="EN2070" name="Single phase Emi Filter Dual Stage High Performance 1-36 Amp" img="en2070.png" />
                                    <Homeproductleft pname="EN2080" name="Single Phase Emi Filter Multi Stage High Performance 1-16 Amp" img="en2080.png" />
                                    <Homeproductleft pname="EN2090" name="Single Phase Emi Filter MUlti stage Very High Performance 1-30 Amp" img="en2090.png" />
                                    <Homeproductleft pname="EN251" name="Three Phase Emi Filter Multi-stage High Performance 4-50 Amp" img="en251.png" />
                                    <Homeproductleft pname="EN358" name="Three Phase Emi Filter for Motor drive & VFD System 7-180 Amp" img="en358.png" /> */}
                                </ul>
                            </div>

                        </div>
                        <div className="col-sm-12 col-xl-5 col-lg-6 pt-3 pl-5" style={{ background: "#eee" }}>

                            <div className="left-product-list">
                                <div className="items-list">
                                    <div className="image-product"><img src="images/TE-Shielded-Filter.jpg" alt="TE Shielded" /></div>
                                    <h3 className="fw-400 text-black mb-1 fs-20 text-uppercase">GP Shielded Room Filter</h3>
                                    <h4 className="fw-500 text-black mb-3 fs-14 text-uppercase">The TE filter range are designed and tested for shielded room requirements, for attenuation from 14khz-40khz.</h4>
                                    <a className="Product-specs-btn text-uppercase" href="images/datasheets/GP-Shielded-room-filter.pdf" target="_blank">Product specs</a>
                                </div>

                                <div className="items-list">
                                    <div className="image-product"><img src="images/tempest-image.jpg" alt="COMMERCIAL INDUSTRIAL" /></div>
                                    <h3 className="fw-400 text-black mb-1 fs-20 text-uppercase">TE Shielded Room Filter</h3>
                                    <h4 className="fw-500 text-black mb-3 fs-14 text-uppercase">Single/Three Phase And Dc EMI Power Line Filters To Meet Stringent Tempest Requirements Of 100db From 14khz To 10ghz.</h4>
                                    <a className="Product-specs-btn text-uppercase" href="#" target="_blank">Product specs</a>
                                </div>

                                <div className="items-list">
                                    <div className="image-product"><img src="images/scif-image.jpg" alt="COMMERCIAL INDUSTRIAL" /></div>
                                    <h3 className="fw-400 text-black mb-1 fs-20">FACILITY/SCIF EMI POWER LINE FITERS</h3>
                                    <h4 className="fw-500 text-black mb-3 fs-14 text-uppercase">Single And Three Phase Emi Power Line Filters To Provide Secure Power To Facilities And Scifs.</h4>
                                    <a className="Product-specs-btn text-uppercase" href="#">Product specs</a>
                                </div>
                                <div className="items-list">
                                    <div className="image-product"><img src="images/CUSTOM-EMI-img.jpg" alt="CUSTOM EMI" /></div>
                                    <h3 className="fw-400 text-black mb-1 fs-20">CUSTOM EMI POWER LINE FILTERS</h3>
                                    <h4 className="fw-500 text-black mb-3 fs-14 text-uppercase">Custom Applications For All Markets And Applications With Ratings To 5kv And 3ka.</h4>
                                    <a className="Product-specs-btn text-uppercase" href="#" target="_blank">Product specs</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="capabilities">
                <div className="container-fluid">
                    <h1 className="text-uppercase fw-600 mb-3 text-black text-center">capabilities</h1>
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-lg-9 mb-4">
                                EMC EMV Filters standard and custom EMI power line filters are designed, manufactured and supported via a global network and infrastructure. From our India manufacturing locations based warehouse and stocking facility, Premier is uniquely positioned to meet the stringent demands required by today's markets/industries.
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="items">
                                    <h5 className="fw-400 mb-3 text-uppercase">Military & Railways Filters</h5>
                                    <img className="alignnone size-full wp-image-1195" src="images/IN-HOUS-img-1.jpg" alt="IN HOUS" width="268" height="192" />
                                </div>
                                <div className="items">
                                    <h5 className="fw-400 mb-3 text-uppercase">Widest Range of IEC Inlet Filters</h5>
                                    <img className="alignnone size-full wp-image-1209" src="images/USA-MFG-img.jpg" alt="USA MFG img" width="268" height="192" />
                                </div>
                                <div className="items">
                                    <h5 className="fw-400 mb-3 text-uppercase">Product Catalogue</h5>
                                    <img className="alignnone wp-image-2307 size-full" src="images/Vertically-integrated-img.png" alt="Vertically integrated img" width="414" height="183" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default Homescreen;
