import React, { useState } from 'react';
import { Modal, CardColumns, Button } from "react-bootstrap";
import Homefilters from './../api/homeproducts';


const DatasheetModal = (props) => {
    //console.log(`Print ${Homefilters[0].modallist[0].datasheetNum}`);
    var datasheetURL = "images/datasheets/";

    
    let modal0 = Homefilters[0].modallist.map((val) => {
        return(
            <li className="listing-item"><a className="title" target="_blank" href={datasheetURL+val.sheetOpen}>{val.datasheetNum}</a></li>
        )
    });
    let modal1 = Homefilters[1].modallist.map((val) => {
        return(
            <li className="listing-item"><a className="title" target="_blank" href={datasheetURL+val.sheetOpen}>{val.datasheetNum}</a></li>
        )
    }); 
    const modal2 = Homefilters[2].modallist.map((val) => {
        return(
             <li className="listing-item"><a className="title" target="_blank" href={datasheetURL+val.sheetOpen}>{val.datasheetNum}</a></li>
        )
    });
    const modal3 = Homefilters[3].modallist.map((val) => {
        return(
             <li className="listing-item"><a className="title" target="_blank" href={datasheetURL+val.sheetOpen}>{val.datasheetNum}</a></li>
        )
    });
    const modal4 = Homefilters[4].modallist.map((val) => {
        return(
             <li className="listing-item"><a className="title" target="_blank" href={datasheetURL+val.sheetOpen}>{val.datasheetNum}</a></li>
        )
    });
    const modal5 = Homefilters[5].modallist.map((val) => {
        return(
             <li className="listing-item"><a className="title" target="_blank" href={datasheetURL+val.sheetOpen}>{val.datasheetNum}</a></li>
        )
    });
    const modal6 = Homefilters[6].modallist.map((val) => {
        return(
             <li className="listing-item"><a className="title" target="_blank" href={datasheetURL+val.sheetOpen}>{val.datasheetNum}</a></li>
        )
    });
    const modal7 = Homefilters[7].modallist.map((val) => {
        return(
             <li className="listing-item"><a className="title" target="_blank" href={datasheetURL+val.sheetOpen}>{val.datasheetNum}</a></li>
        )
    });
    const modal8 = Homefilters[8].modallist.map((val) => {
        return(
             <li className="listing-item"><a className="title" target="_blank" href={datasheetURL+val.sheetOpen}>{val.datasheetNum}</a></li>
        )
    });


    // const modal = Homefilters.map((cval,index) => {        
    //     return(
    //         // <div class="paoc-popup-modal-cnt modal fade" id={`modal${cval.id}`} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    //         //     <div class="modal-dialog" role="document">
    //         //         <div class="modal-content">
    //         //             <div class="modal-header">
    //         //             <h2 id="mce_0">EMC/EMV - {cval.pname}</h2>
    //         //                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    //         //                 <span aria-hidden="true">&times;</span>
    //         //                 </button>
    //         //             </div>
    //         //             <div class="modal-body">
    //         //                 <ul className="display-posts-listing" id={`ul-${cval.id}`}>
    //         //                     {modal0}
    //         //                 </ul>
    //         //             </div>
    //         //         </div>
    //         //     </div>
    //         // </div>
    //     )
    // })


    return (
        <>
            {/* {modal} */}
            <div class="paoc-popup-modal-cnt modal fade" id="modal0" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h2 id="mce_0">EMC/EMV - {Homefilters[0].pname}</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ul className="display-posts-listing">
                                {modal0}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paoc-popup-modal-cnt modal fade" id="modal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h2 id="mce_0">EMC/EMV - {Homefilters[1].pname}</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ul className="display-posts-listing">
                                {modal1}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paoc-popup-modal-cnt modal fade" id="modal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h2 id="mce_0">EMC/EMV - {Homefilters[2].pname}</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ul className="display-posts-listing">
                                {modal2}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paoc-popup-modal-cnt modal fade" id="modal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h2 id="mce_0">EMC/EMV - {Homefilters[3].pname}</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ul className="display-posts-listing">
                                {modal3}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paoc-popup-modal-cnt modal fade" id="modal4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h2 id="mce_0">EMC/EMV - {Homefilters[4].pname}</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ul className="display-posts-listing">
                                {modal4}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paoc-popup-modal-cnt modal fade" id="modal5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h2 id="mce_0">EMC/EMV - {Homefilters[5].pname}</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ul className="display-posts-listing">
                                {modal5}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paoc-popup-modal-cnt modal fade" id="modal6" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h2 id="mce_0">EMC/EMV - {Homefilters[6].pname}</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ul className="display-posts-listing">
                                {modal6}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paoc-popup-modal-cnt modal fade" id="modal7" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h2 id="mce_0">EMC/EMV - {Homefilters[7].pname}</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ul className="display-posts-listing">
                                {modal7}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paoc-popup-modal-cnt modal fade" id="modal8" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h2 id="mce_0">EMC/EMV - {Homefilters[8].pname}</h2>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ul className="display-posts-listing">
                                {modal8}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DatasheetModal;
