//import React from 'react';

const Homefilters = [
	{
		id: 0,
		pname: "EN2010",
		name: "Single phase EMI Filter General Purpose  1-30 Amp",
		img: "en2010.png",
		modallist: [{
			id: 1,
			datasheetNum: "EN2010-1-F, 1Amp",
			sheetOpen: "EN2010-1-F.pdf"
		}, {
			id: 2,
			datasheetNum: "EN2010-3-F, 3Amp",
			sheetOpen: "EN2010-3-F.pdf"
		},{
			id: 3,
			datasheetNum: "EN2010-6-F, 6Amp",
			sheetOpen: "EN2010-6-F.pdf"
		},{
			id: 4,
			datasheetNum: "EN2010-10-F, 10Amp",
			sheetOpen: "EN2010-10-F.pdf"
		},{
			id: 5,
			datasheetNum: "EN2010-12-F, 12Amp",
			sheetOpen: "EN2010-12-F.pdf"
		},{
			id: 6,
			datasheetNum: "EN2010-13-F, 13Amp",
			sheetOpen: "EN2010-13-F.pdf"
		},{
			id: 7,
			datasheetNum: "EN2010-16-F, 16Amp",
			sheetOpen: "EN2010-16-F.pdf"
		},{
			id: 8,
			datasheetNum: "EN2010-20-F, 20Amp",
			sheetOpen: "EN2010-20-F.pdf"
		},{
			id: 9,
			datasheetNum: "EN2010-20-S, 20Amp",
			sheetOpen: "EN2010-20-S.pdf"
		},{
			id: 10,
			datasheetNum: "EN2010-30-S, 30Amp",
			sheetOpen: "EN2010-30-S.pdf"
		},{
			id: 11,
			datasheetNum: "EN2010B-6-F, 6Amp",
			sheetOpen: "EN2010B-6-F.pdf"
		},{
			id: 12,
			datasheetNum: "EN2010B-13-F, 13Amp",
			sheetOpen: "EN2010B-13-F.pdf"
		},{
			id: 13,
			datasheetNum: "EN2010B-16-F, 16Amp",
			sheetOpen: "EN2010B-16-F.pdf"
		}],
	},
	{
		id: 1,
		pname: "EN2020",
		name: "Single phase EMI Filter General Purpose  1-30 Amp",
		img: "en2020.png",
		modallist: [{
			id: 1,
			datasheetNum: "EN2020-1-F, 1Amp",
			sheetOpen: "EN2020-1-F.pdf"
		}, {
			id: 2,
			datasheetNum: "EN2020-3-F, 3Amp",
			sheetOpen: "EN2020-3-F.pdf"
		}, {
			id: 3,
			datasheetNum: "EN2020-6-F, 6Amp",
			sheetOpen: "EN2020-6-F.pdf"
		}, {
			id: 4,
			datasheetNum: "EN2020-10-F, 10Amp",
			sheetOpen: "EN2020-10-F.pdf"
		}, {
			id: 5,
			datasheetNum: "EN2020-12-F, 12Amp",
			sheetOpen: "EN2020-12-F.pdf"
		}, {
			id: 6,
			datasheetNum: "EN2020-16-F, 16Amp",
			sheetOpen: "EN2020-16-F.pdf"
		}, {
			id: 7,
			datasheetNum: "EN2020-20-F, 20Amp",
			sheetOpen: "EN2020-20-F.pdf"
		}, {
			id: 8,
			datasheetNum: "EN2020-20-S, 20Amp",
			sheetOpen: "EN2020-20-S.pdf"
		}, {
			id: 9,
			datasheetNum: "EN2020-30-S, 30Amp",
			sheetOpen: "EN2020-30-S.pdf"
		}, {
			id: 10,
			datasheetNum: "EN2020B-6-F, 6Amp",
			sheetOpen: "EN2020B-6-F.pdf"
		}],
	},
	{
		id: 2,
		pname: "EN2030",
		name: "Single Phase EMI Filter with High Attenuation Performance 1-30 Amp",
		img: "en2030.png",
		modallist: [{
			id: 1,
			datasheetNum: "EN2030-1-F, 1Amp",
			sheetOpen: "EN2030-1-F.pdf"
		}, {
			id: 2,
			datasheetNum: "EN2030-3-F, 3Amp",
			sheetOpen: "EN2030-3-F.pdf"
		}, {
			id: 3,
			datasheetNum: "EN2030-4-F, 4Amp",
			sheetOpen: "EN2030-4-F.pdf"
		}, {
			id: 4,
			datasheetNum: "EN2030-6-F, 6Amp",
			sheetOpen: "EN2030-6-F.pdf"
		}, {
			id: 5,
			datasheetNum: "EN2030-10-F, 10Amp",
			sheetOpen: "EN2030-10-F.pdf"
		}, {
			id: 6,
			datasheetNum: "EN2030-12-F, 12Amp",
			sheetOpen: "EN2030-12-F.pdf"
		}, {
			id: 7,
			datasheetNum: "EN2030-16-F, 16Amp",
			sheetOpen: "EN2030-16-F.pdf"
		}, {
			id: 8,
			datasheetNum: "EN2030-4-F, 4Amp",
			sheetOpen: "EN2030-4-F.pdf"
		}, {
			id: 9,
			datasheetNum: "EN2030-20-F, 20Amp",
			sheetOpen: "EN2030-20-F.pdf"
		}, {
			id: 10,
			datasheetNum: "EN2030-20-S, 20Amp",
			sheetOpen: "EN2030-20-S.pdf"
		}, {
			id: 11,
			datasheetNum: "EN2030-30-S, 30Amp",
			sheetOpen: "EN2030-30-S.pdf"
		}]
	},
	{
		id: 3,
		pname: "EN2060",
		name: "Single Phase EMI Filter Dual Stage General Purpose 1-30 Amp",
		img: "en2060.png",
		modallist: [{
			id: 1,
			datasheetNum: "EN2060-3-F, 3Amp",
			sheetOpen: "EN2060-3-F.pdf"
		}, {
			id: 2,
			datasheetNum: "EN2060-6-F, 6Amp",
			sheetOpen: "EN2060-6-F.pdf"
		}, {
			id: 3,
			datasheetNum: "EN2060-10-F, 10Amp",
			sheetOpen: "EN2060-10-F.pdf"
		}, {
			id: 4,
			datasheetNum: "EN2060-16-F, 16Amp",
			sheetOpen: "EN2060-16-F.pdf"
		}, {
			id: 5,
			datasheetNum: "EN2060-20-F, 20Amp",
			sheetOpen: "EN2060-20-F.pdf"
		}, {
			id: 6,
			datasheetNum: "EN2060-20-S, 20Amp",
			sheetOpen: "EN2060-20-S.pdf"
		}, {
			id: 7,
			datasheetNum: "EN2060-30-S, 30Amp",
			sheetOpen: "EN2060-30-S.pdf"
		}],
	},
	{
		id: 4,
		pname: "EN2070",
		name: "Single phase Emi Filter Dual Stage High Performance 1-36 Amp",
		img: "en2070.png",
		modallist: [{
			id: 1,
			datasheetNum: "EN2070-10-F, 10Amp",
			sheetOpen: "EN2070-10-F.pdf"
		}, {
			id: 2,
			datasheetNum: "EN2070-12-F, 12Amp",
			sheetOpen: "EN2070-12-F.pdf"
		}, {
			id: 3,
			datasheetNum: "EN2070-16-F, 16Amp",
			sheetOpen: "EN2070-16-F.pdf"
		}, {
			id: 4,
			datasheetNum: "EN2070-16-S, 16Amp",
			sheetOpen: "EN2070-16-S.pdf"
		}, {
			id: 5,
			datasheetNum: "EN2070-25-S, 25Amp",
			sheetOpen: "EN2070-25-S.pdf"
		}, {
			id: 6,
			datasheetNum: "EN2070-36-S, 36Amp",
			sheetOpen: "EN2070-36-S.pdf"
		}],
	},
	{
		id: 5,
		pname: "EN2080",
		name: "Single Phase Emi Filter Multi Stage High Performance 1-16 Amp",
		img: "en2080.png",
		modallist: [{
			id: 1,
			datasheetNum: "EN2080-1-F, 1Amp",
			sheetOpen: "EN2080-1-F.pdf"
		}, {
			id: 2,
			datasheetNum: "EN2080-3-F, 3Amp",
			sheetOpen: "EN2080-3-F.pdf"
		}, {
			id: 3,
			datasheetNum: "EN2080-10-F, 10Amp",
			sheetOpen: "EN2080-10-F.pdf"
		}, {
			id: 4,
			datasheetNum: "EN2080-12-F, 12Amp",
			sheetOpen: "EN2080-12-F.pdf"
		}, {
			id: 5,
			datasheetNum: "EN2080-12-S, 12Amp",
			sheetOpen: "EN2080-12-S.pdf"
		}, {
			id: 6,
			datasheetNum: "EN2080-16-F, 16Amp",
			sheetOpen: "EN2080-16-F.pdf"
		}, {
			id: 7,
			datasheetNum: "EN2080-16-S, 16Amp",
			sheetOpen: "EN2080-16-S.pdf"
		}],
	},
	{
		id: 6,
		pname: "EN2090",
		name: "Single Phase Emi Filter MUlti stage Very High Performance 1-30 Amp",
		img: "en2090.png",
		modallist: [{
			id: 1,
			datasheetNum: "EN2090-1-F, 1Amp",
			sheetOpen: "EN2090-1-F.pdf"
		}, {
			id: 2,
			datasheetNum: "EN2090-3-F, 3Amp",
			sheetOpen: "EN2090-3-F.pdf"
		}, {
			id: 3,
			datasheetNum: "EN2090-4-F, 4Amp",
			sheetOpen: "EN2090-4-F.pdf"
		}, {
			id: 4,
			datasheetNum: "EN2090-6-F, 6Amp",
			sheetOpen: "EN2090-6-F.pdf"
		}, {
			id: 5,
			datasheetNum: "EN2090-10-F, 10Amp",
			sheetOpen: "EN2090-10-F.pdf"
		}, {
			id: 6,
			datasheetNum: "EN2090-12-F, 12Amp",
			sheetOpen: "EN2090-12-F.pdf"
		}, {
			id: 7,
			datasheetNum: "EN2090-16-F, 16Amp",
			sheetOpen: "EN2090-16-F.pdf"
		}, {
			id: 8,
			datasheetNum: "EN2090-20-F, 20Amp",
			sheetOpen: "EN2090-20-F.pdf"
		}, {
			id: 9,
			datasheetNum: "EN2090-20-S, 20Amp",
			sheetOpen: "EN2090-20-S.pdf"
		}, {
			id: 10,
			datasheetNum: "EN2090-30-S, 30Amp",
			sheetOpen: "EN2090-30-S.pdf"
		}, {
			id: 11,
			datasheetNum: "EN2090B-16-F, 16Amp",
			sheetOpen: "EN2090B-16-F.pdf"
		}],
	},
	{
		id: 7,
		pname: "EN251",
		name: "Three Phase Emi Filter Multi-stage High Performance 4-50 Amp",
		img: "en251.png",
		modallist: [],
	},
	{
		id: 8,
		pname: "EN358",
		name: "Three Phase Emi Filter for Motor drive & VFD System 7-180 Amp",
		img: "en358.png",
		modallist: [{
			id: 1,
			datasheetNum: "EN358-7-T, 7Amp",
			sheetOpen: "EN358-7-T.pdf"
		}, {
			id: 2,
			datasheetNum: "EN358-16-T, 16Amp",
			sheetOpen: "EN358-16-T.pdf"
		}, {
			id: 3,
			datasheetNum: "EN358-30-T, 30Amp",
			sheetOpen: "EN358-30-T.pdf"
		}, {
			id: 4,
			datasheetNum: "EN358-42-T, 42Amp",
			sheetOpen: "EN358-42-T.pdf"
		}, {
			id: 5,
			datasheetNum: "EN358-55-T, 55Amp",
			sheetOpen: "EN358-55-T.pdf"
		}, {
			id: 6,
			datasheetNum: "EN358-75-T, 75Amp",
			sheetOpen: "EN358-75-T.pdf"
		}, {
			id: 7,
			datasheetNum: "EN358-100-T, 100Amp",
			sheetOpen: "EN358-100-T.pdf"
		}, {
			id: 8,
			datasheetNum: "EN358-130-T, 130Amp",
			sheetOpen: "EN358-130-T.pdf"
		}, {
			id: 9,
			datasheetNum: "EN358-180-T, 180Amp",
			sheetOpen: "EN358-180-T.pdf"
		}],
	}
	
]

export default Homefilters; 