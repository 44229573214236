import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import Data from './practice/Data'


ReactDOM.render(
  <React.StrictMode>
      <App />
   </React.StrictMode>,
  document.getElementById('root')
);

